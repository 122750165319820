import React from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Header = () => {
  return (
    <header>
      {/* <div className="header-area">
        <div className="main-header">
          <div className="header-bottom header-sticky">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="/assets/img/logo.jpg"
                        alt="Bio Care Foundation"
                        className="biocare-logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9">
                  <div className="menu-wrapper d-flex align-items-center justify-content-end">
                    <div className="main-menu d-none d-lg-block">
                      <nav>
                        <ul id="navigation">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="#">About</a>
                          </li>
                          <li>
                            <a href="#">Campaigns</a>
                          </li>
                          <li>
                            <a href="#">Events </a>
                          </li>
                          <li>
                            <a href="#">Our Impact</a>
                          </li>
                          <li>
                            <a href="/team">Our team</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <div className="header-right-btn d-none d-lg-block ml-20">
                      <a href="/contact" className="btn header-btn">
                        Donate
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mobile_menu d-block d-lg-none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="top-nav">
        <div className="col-xl-3 col-lg-3">
          <div className="logo">
            <Link to="/">
              <img
                src="/assets/img/logo.jpg"
                alt="Bio Care Foundation"
                className="biocare-logo"
                // style={{ width: "130px" }}
              />
            </Link>
          </div>
        </div>
        <input id="menu-toggle" type="checkbox" />
        <label className="menu-button-container" htmlFor="menu-toggle">
          <div className="menu-button"></div>
        </label>
        <ul className="menu">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="#">About</a>
          </li>
          <li>
            <a href="#">Campaigns</a>
          </li>
          <li>
            <a href="#">Events </a>
          </li>
          <li>
            <a href="#">Our Impact</a>
          </li>
          <li>
            <a href="/team">Our team</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
        </ul>

        <div className="header-right-btn d-none d-lg-block ml-20">
          <a href="#" className="btn header-btn">
            Donate
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from "react";

const Footer = () => {
  return (
    <footer>
      <div
        className="footer-wrapper section-bg2"
        style={{ background: "black" }}
      >
        <div className="footer-area footer-padding">
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div className="single-footer-caption mb-50">
                  <div className="single-footer-caption mb-30">
                    <div className="footer-tittle">
                      <div className="footer-logo mb-20">
                        <a href="/"> BIO CARE FOUNDATION </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Contact Info</h4>
                    <ul>
                      <li>
                        <p>
                          Chandkhani. P.O: Kadamtala. Pin: 788166, House No:
                          208, Karimganj, Assam
                        </p>
                      </li>
                      <li>
                        <a href="#">Phone : +91-8133814006</a>
                      </li>
                      <li>
                        <a href="#">Email : foundationbiocare@gmail.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Important Link</h4>
                    <ul>
                      <li>
                        <a href="#"> View Project</a>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                      <li>
                        <a href="#">Testimonial</a>
                      </li>
                      <li>
                        <a href="#">Proparties</a>
                      </li>
                      <li>
                        <a href="#">Support</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Newsletter</h4>
                    <div className="footer-pera footer-pera2">
                      <p>Connect with us</p>
                    </div>

                    <div className="footer-form">
                      <div id="mc_embed_signup">
                        <form
                          target="_blank"
                          method="get"
                          className="subscribe_form relative mail_part"
                        >
                          <input
                            type="email"
                            name="email"
                            id="newsletter-form-email"
                            placeholder="Email Address"
                            className="placeholder hide-on-focus"
                          />
                          <div className="form-icon">
                            <button
                              type="submit"
                              name="submit"
                              id="newsletter-submit"
                              className="email_icon newsletter-submit button-contactForm"
                            >
                              <img src="assets/img/gallery/form.png" alt="" />
                            </button>
                          </div>
                          <div className="mt-10 info"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="container">
            <div className="footer-border">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="col-xl-10 col-lg-9">
                  <div className="footer-copy-right">
                    <p>
                      Copyright &copy; {new Date().getFullYear()} All rights
                      reserved | Made with{" "}
                      <i className="fa fa-heart" aria-hidden="true"></i> by{" "}
                      <a href="https://afridi.vercel.app" target="_blank">
                        Afridi Ahmed
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3">
                  <div className="footer-social f-right">
                    <a href="https://twitter.com/foundationbioca?t=_Y-NYNltMwf4z2IwrGlj7g&s=08">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.facebook.com/foundationbiocare/">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://instagram.com/foundationbiocare?utm_medium=copy_link">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getCardData, getHomeData, getSlider, graphqlAPI } from "../service";
import request from "graphql-request";
import Loader from "./loader.gif";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        color: "grey",
        fontSize: "30px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        color: "grey",
        fontSize: "30px",
      }}
      onClick={onClick}
    />
  );
}

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [cardDatas, setCardDatas] = useState([]);
  const [sliderDatas, setSliderDatas] = useState([]);
  const [allhomeData, setAllHomeData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    homeData();
    cardData();
    sliderData();
  }, []);

  const homeData = async () => {
    const data = await request(graphqlAPI, getHomeData);
    setAllHomeData(data.homesConnection.edges[0].node);
  };

  const cardData = async () => {
    const data = await request(graphqlAPI, getCardData);
    setCardDatas(data.cardsConnection.edges);
  };

  const sliderData = async () => {
    const data = await request(graphqlAPI, getSlider);
    setSliderDatas(data.slidersConnection.edges[0].node.slider);
    setLoading(false);
  };

  return (
    <main>
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ height: "100vh" }}
        >
          <img
            src={Loader}
            alt="loading"
            style={{ width: "100px", height: "100px", marginTop: "25vh" }}
          />
        </div>
      ) : (
        <>
          <div className="testimonial-area testimonial-padding">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-10">
                  <div className="section-tittle text-center mb-80">
                    <h2>{allhomeData?.missionTitle}</h2>
                  </div>
                  <div className="h1-testimonial-active dot-style">
                    <Slider {...settings}>
                      {sliderDatas.map((item, id) => (
                        <div
                          className="single-testimonial text-center"
                          key={id}
                        >
                          <div className="testimonial-caption">
                            <div className="testimonial-founder">
                              <div className="founder-img mb-40">
                                <img
                                  src={item.url}
                                  alt="slider-image"
                                  className="slider-image__personality"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-area">
            <div className="slider-active">
              <div className="single-slider slider-height d-flex align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                      <div className="hero__caption">
                        <h1 data-animation="fadeInUp" data-delay=".6s">
                          {allhomeData?.helpingTitle}
                        </h1>
                        <p data-animation="fadeInUp" data-delay=".8s">
                          {allhomeData?.helpingDescription}
                        </p>
                        <div className="hero__btn">
                          <a
                            href="industries.html"
                            className="btn hero-btn mb-10"
                            data-animation="fadeInLeft"
                            data-delay=".8s"
                          >
                            Donate
                          </a>
                          <a
                            href="industries.html"
                            className="cal-btn ml-15"
                            data-animation="fadeInRight"
                            data-delay="1.0s"
                          >
                            <i className="flaticon-null"></i>
                            <p>+91-8133814006</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="about-low-area section-padding2">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-10">
                  <div className="about-caption mb-50">
                    <div className="section-tittle mb-35">
                      <span>{allhomeData?.about}</span>
                      <h2>{allhomeData?.missionAndVission}</h2>
                    </div>
                    <p>{allhomeData?.aboutDescription}</p>
                  </div>
                  <a href="about.html" className="btn">
                    About US
                  </a>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="about-img">
                    <div className="about-font-img d-none d-lg-block">
                      <img src="assets/img/gallery/about2.png" alt="" />
                    </div>
                    <div className="about-back-img">
                      <img src="assets/img/gallery/about1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="service-area section-padding30">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10 col-sm-10">
                  <div className="section-tittle text-center mb-80">
                    <span>{allhomeData?.weAreDoing}</span>
                    <h2>{allhomeData?.whatWeDescription}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                {cardDatas?.map((card, id) => (
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-cat text-center mb-50">
                      <div className="cat-icon">
                        <span className="flaticon-null-1"></span>
                      </div>
                      <div className="cat-cap">
                        <h5>
                          <a href="services.html">{card.node.title}</a>
                        </h5>
                        <p>{card.node.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default Home;

import request from "graphql-request";
import React, { useEffect, useState } from "react";
import { getTeamData, graphqlAPI } from "../service";
import "./team.css";
import Loader from "./loader.gif";

const Team = () => {
  const [teamDatas, setTeamDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    teamData();
  }, []);

  const teamData = async () => {
    const data = await request(graphqlAPI, getTeamData);
    setTeamDatas(data.teamsConnection.edges);
    setLoading(false);
    console.log(data.teamsConnection.edges);
  };

  return (
    <div class="container">
      <div class="section-title">
        <h1>Our Team</h1>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ height: "100vh" }}
        >
          <img
            src={Loader}
            alt="loading"
            style={{ width: "100px", height: "100px", marginTop: "10vh" }}
          />
        </div>
      ) : (
        <div class="row">
          {teamDatas?.map((eachTeam, id) => (
            <div class="column" key={id}>
              <div class="team">
                <div class="team-img">
                  <img
                    src={eachTeam.node?.photo?.url}
                    alt={eachTeam.node?.name}
                  />
                </div>
                <div class="team-content">
                  <h2>{eachTeam.node?.name}</h2>
                  <h3>{eachTeam.node?.designation}</h3>
                  <h4>{eachTeam.node?.email}</h4>
                </div>

                {/* <div class="team-social">
                <a href="#" class="social-fb">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#" class="social-in">
                  <i class="fab fa-instagram"></i>
                </a>
              </div> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Team;

import { gql } from "graphql-request";

export const graphqlAPI =
  "https://api-ap-south-1.graphcms.com/v2/cl0z12d574loc01z3flaj6t5i/master";

export const getHomeData = gql`
  query HomeSlider {
    homesConnection {
      edges {
        node {
          about
          aboutDescription
          helpingDescription
          helpingTitle
          missionAndVission
          missionTitle
          weAreDoing
          whatWeDescription
        }
      }
    }
  }
`;

export const getCardData = gql`
  query MyQuery {
    cardsConnection {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;

export const getSlider = gql`
  query MyQuery {
    slidersConnection {
      edges {
        node {
          slider {
            url
          }
        }
      }
    }
  }
`;

export const getTeamData = gql`
  query MyQuery {
    teamsConnection {
      edges {
        node {
          designation
          email
          name
          photo {
            url
          }
        }
      }
    }
  }
`;
